
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiDetail, apiModelEdit } from "@/api/model";
import { apiShopLists } from "@/api/shop";

import { PageMode } from "@/utils/type";
import MaterialSelect from "@/components/material-select/index.vue";
import AddPrice from "@/components/goods/add-price.vue";

@Component({
    components: {
        MaterialSelect,
        AddPrice,
    },
})
export default class ShopModelEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD; // 当前页面: add-添加角色 edit-编辑角色

    // 表
    lists: Array<object> = [];
    pager: any;
    searchObj: any;

    activeName = "basic";
    lang: any = [];
    // 表单数据
    form: any = {
        title: "",
        en_title: "",
        image: "",
        is_show: 1,
        isable: 1,
        price: "",
        sort: "",
        cost_price: "",
        factory_id: "",
        factory_price: "",
        desc_list: [],
        spec_value_list: [
            {
                id: "",
                image: "",
                sell_price: "",
                lineation_price: "",
                cost_price: "",
                stock: "",
                volume: "",
                weight: "",
                bar_code: "",
            },
        ],
    };



    // 表单校验
    rules: object = {
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
    };

    // 必传字段与对应的选项卡名字
    requireFields = [
        {
            fields: ["code", "name", "category_id", "goods_image"],
            activeName: "basic",
        },
        {
            fields: [
                "spec_value_list[0].stock",
                "spec_value_list[0].factory_price",
                "spec_value_list[0].cost_price",
                "spec_value_list[0].length",
                "spec_value_list[0].width",
                "spec_value_list[0].high",
            ],
            activeName: "price",
        },
        {
            fields: ["express_money", "express_template_id", "delivery_content"],
            activeName: "logistics",
        },
    ];

    // 规格绑定的数据
    specData: any = {
        spec_type: 1,
        spec_value: [],
        spec_value_list: [],
        specs_single: {},
    };

    factoryList: any[] = [];

    /** E Data **/

    /** S Methods **/
    // 检查是否包含空字符串
    checkEmptyValues(obj: any) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === '') {
                return true;
            }
        }
        return false;
    }
    // 提交表单
    onSubmit(formName: string) {
        if (this.lang.length > 1) {
            // 多规格
            if (this.form.spec_type === 2) {
                let nameArr: any = [];
                let valueArr: any = [];
                this.form.spec_value.forEach((v: any) => {
                    nameArr.push(this.checkEmptyValues(v.name));
                    v.spec_list.forEach((item: any) => {
                        valueArr.push(this.checkEmptyValues(item.value));
                    })
                })
                if (nameArr.includes(true)) return this.$message.error('请输入完整的规格名');
                if (valueArr.includes(true)) return this.$message.error('请输入完整的规格值');
            }
        }
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean, object: any): any => {
            if (!valid) {
                const fieldsitem = this.requireFields.find((item) => {
                    for (const value of item.fields) {
                        if (object[value]) {
                            this.$message.error(object[value][0].message);
                            return true;
                        }
                    }
                });
                fieldsitem && (this.activeName = fieldsitem.activeName);
                return false;
            } else {
                if (typeof this.form.cate_id === 'object') this.form.cate_id = this.form.cate_id.join(",");
                let form = JSON.parse(JSON.stringify(this.form));

                if (this.lang.length > 1) {
                    form.spec_value.forEach((v: any) => {
                        if (v.name) v.name = JSON.stringify(v.name);
                        v.spec_list.forEach((item: any) => {
                            if (item.value) item.value = JSON.stringify(item.value);
                        })
                    })
                    form.spec_value_list.forEach((v: any) => {
                        if (v.spec_value_str) {
                            let jsonString = JSON.stringify(v.spec_value_str); // 将数组转换为字符串
                            let result = jsonString.slice(1, -1); // 去除首尾的中括号
                            v.spec_value_str = result;
                        }
                        if (v.value) {
                            let jsonString = JSON.stringify(v.value); // 将数组转换为字符串
                            let result = jsonString.slice(1, -1); // 去除首尾的中括号
                            v.value = result;
                        }

                    })
                    let desc_list: any = [];
                    this.lang.forEach((v: any) => {
                        desc_list.push({
                            locale: v,
                            content: '',
                            name: v === 'zh_cn' ? this.form.title : this.form.en_title,
                            copysid_sid: this.form.id
                        })
                    })
                    form.desc_list = desc_list;
                }
                console.log(form)
                apiModelEdit(form).then((res) => {
                    this.$router.push({
                        name: "shop_model",
                        params: {
                            pager: this.pager as any,
                            searchObj: this.searchObj as any,
                        },
                        query: {
                            id: this.form.sid as any,
                        },
                    });
                });
            }
        });
    }

    // 初始化表单数据
    async initFormDataForNoticeEdit(): Promise<void> {
        const res = await apiDetail({
            sid: this.form.sid,
            mx_id: this.form.mx_id,
        });


        res.spec_value.forEach((v: any) => {
            if (typeof v.name === 'string' && v.name.includes(',')) {
                v.name = JSON.parse(v.name);
                this.lang = Object.keys(v.name);
            }
            if (v.spec_list) {
                v.spec_list.forEach((item: any) => {
                    if (typeof item.value === 'string' && item.value.includes(',')) {
                        item.value = JSON.parse(item.value);
                    }
                })
            }
        })

        // 商品编辑多语言设置默认参数
        if (this.lang.length > 1) {
            let multiLanguage: any = {};
            for (const item of this.lang) {
                this.$set(multiLanguage, item, '');
            }
            // 设置商品详情
            let desc_list: any = []
            this.lang.forEach((v: any) => {
                desc_list.push({
                    name: '',
                    content: '',
                    locale: v,
                })
            })
            this.$set(this.form, 'desc_list', this.mergeObjectsByLocale(desc_list, res.desc_list ? res.desc_list : []));
            console.log('this.form-------------', this.form)
            this.form.desc_list.forEach((v: any) => {
                this.$set(v, 'content', v.content)
            })
            // 设置商品名称
            if (this.form.name) {
                this.form.desc_list.forEach((v: any) => {
                    if (v.locale === 'zh_cn') {
                        v.name = res.name;
                    }
                })
            }
            // 设置多规格
            if (res.spec_value) {
                res.spec_value.forEach((v: any) => {
                    let name = v.name;
                    console.log('let name', v.name)
                    console.log('parseString', this.parseString(name))
                    v.name = this.parseString(name)
                    v.spec_list.forEach((item: any) => {
                        let value = item.value;
                        item.value = this.parseString(value);
                    })
                })
            }
        } else {
            res.spec_value.forEach((v: any) => {
                console.log('res.spec_value.forEach', this.processName(v.name))
                if (v.name) v.name = this.processName(v.name);
                v.spec_list.forEach((item: any) => {
                    if (item.value) item.value = this.processName(item.value);
                })
            })
            res.spec_value_list.forEach((v: any) => {
                if (v.spec_value_str) {
                    v.spec_value_str = v.spec_value_str.includes('},{') ? this.mergeZhCnValues(JSON.parse(`[${v.spec_value_str}]`)) : v.spec_value_str;
                }

            })
        }


        res.cate_id = res.cate_id.split(",");

        console.log('res', res)

        this.initData(res);
        Object.keys(res).map((item) => {
            this.$set(this.form, item, res[item]);
        });


    }


    @Watch("specData", { deep: true })
    specDataChange(val: any) {
        this.form.spec_type = val.spec_type;
        if (val.spec_type == 1) {
            this.form.spec_value_list = [val.specs_single];
            this.form.spec_value = [];
            return;
        }
        this.form.spec_value_list = val.spec_value_list;
        this.form.spec_value = val.spec_value;
    }

    processName(name: string) {
        try {
            const jsonData = JSON.parse(name);
            if (jsonData && jsonData.zh_cn) {
                return jsonData.zh_cn;
            }
        } catch (error) {
            // 处理普通字符串的情况
            return name;
        }
    }

    parseString(inputString: string) {
        try {
            const data = JSON.parse(inputString);
            if (typeof data === 'object' && data !== null) {
                return data;
            }
        } catch (error) {
            // Parsing failed, return the original input string
            const langObject = this.lang.reduce((acc: any, item: any) => {
                acc[item] = '';
                return acc;
            }, {});
            console.log(inputString)
            return inputString;
        }
        const langObject = this.lang.reduce((acc: any, item: any) => {
            acc[item] = '';
            return acc;
        }, {});
        return { ...langObject, zh_cn: inputString };
    }

    mergeObjectsByLocale(list1: any, list2: any) {
        let mergedList = [...list1];

        for (let obj2 of list2) {
            let matchingObj = mergedList.find(obj1 => obj1.locale === obj2.locale);

            if (matchingObj) {
                Object.assign(matchingObj, obj2);
            } else {
                mergedList.push(obj2);
            }
        }
        console.log('mergedList', mergedList)
        return mergedList;
    }

    mergeZhCnValues(value: any): string {
        if (value.length === 0) {
            return ''; // 处理空数组情况，返回空字符串
        }
        const zh_cnArray = value.map((obj: any) => obj.zh_cn);
        const mergedString = zh_cnArray.join(',');
        return mergedString;
    }

    // 初始化规格数据
    initData(data: any = {}) {
        const spec_value = [
            {
                has_image: false,
                id: "",
                name: "",
                spec_list: [
                    {
                        id: "",
                        value: "",
                        image: "",
                    },
                ],
            },
        ];
        const spec_value_list = [
            {
                id: "",
                image: "",
                sell_price: "",
                lineation_price: "",
                cost_price: "",
                stock: "",
                volume: "",
                weight: "",
                bar_code: "",
            },
        ];

        const specData: any = {
            spec_value: data.spec_value || spec_value,
            spec_value_list: data.spec_value_list || spec_value_list,
            spec_type: data.spec_type || 1,
            specs_single: spec_value_list[0],
        };
        specData.spec_value.forEach((item: any) => {
            item.has_image = false;
            item.spec_list.forEach((sitem: any) => {
                sitem.image = "";
            });
        });

        if (data.spec_type == 1) {
            specData.spec_value = spec_value;
            specData.specs_single = data.spec_value_list[0];
        }
        Object.assign(this.specData, specData);
        // this.loading = false
    }

    // 工厂管理列表
    getFactoryLists() {
        apiShopLists({}).then((res) => {
            this.factoryList = res.lists;
        });
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;
        if (query.mode) this.mode = query.mode;
        this.pager = this.$route.params.pager as any;
        this.searchObj = this.$route.params.searchObj as any;
        if (this.mode === PageMode.EDIT) {
            this.form.sid = query.sid * 1;
            this.form.mx_id = query.mx_id * 1;
            this.initFormDataForNoticeEdit();
            // this.getFactoryLists();
            // this.initData();
        }
    }

    /** E Life Cycle **/
}
